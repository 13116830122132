import React from 'react';
import { useStaticQuery, graphql, Link } from "gatsby"
import _ from "lodash";

import Layout from "gatsby-theme-starberry-lomondgroup/src/components/Layout"
import StaticBanner from "gatsby-theme-starberry-lomondgroup/src/components/StaticBanner/StaticBanner"
import OurTeam from "gatsby-theme-starberry-lomondgroup/src/components/OurTeam/OurTeam";


const TeamTemplate = (props) => {

  var GQLPage = props.GQLPage;
  var GQLModules = props.GQLModules;

  const data = useStaticQuery(graphql`
      query TeamQueryCustom{
        glstrapi {
          teamsConnection(where: {publish:true}) {
            aggregate {
              count
            }
          }

          teams(sort:"rank:asc",where:{publish:true}) {
            id
            name
            slug
            image {
              alternativeText
              url
            }
            title
            video_url
            mobile_no
            designation
            email
            content
            category
            show_on_teams
            imagetransforms
            offices {
              id
              name
              slug
            }
          }
        }
  }`)

  var teams = data.glstrapi?.teams;
  var teams_count = data.glstrapi?.teamsConnection?.aggregate?.count;
  var teams_cat = _.filter(teams, 'category')
  //console.log('teamxxs', teams_cat)
  const categories =  _.uniq(_.map(teams_cat, 'category'));

  //console.log('teams_count', teams_count, data.glstrapi?.teamsConnection)

  return (
    <div className="static-page ">
      <Layout seo_meta={GQLPage.seo_meta} menu_slug={GQLPage?.menu?.slug} popular_search={GQLPage?.menu?.popular_search} GQLPage={GQLPage}>
        {GQLPage?.banner_section?.banner_image && <StaticBanner banner_section={GQLPage.banner_section} GQLPage={GQLPage} />}
        <section className="static-details team_page">
          <OurTeam teams={teams.filter(item => item.show_on_teams !== false)} categories={categories} teams_count={teams_count} content={GQLPage.content} />
        </section>
      </Layout>
    </div>
  )

}

export default TeamTemplate